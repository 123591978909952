import React, {  } from 'react';
import config from 'react-global-configuration'
import { Route, Switch } from 'react-router-dom'
import Settings from '../pages/Settings'
import GlobalSettings from '../pages/GlobalSettings'
import QRCodeQueueIn from '../queue/QRCodeQueueIn'
import QRCode from '../queue/QRCode'
import QueueEditor from '../queue/QueueEditor'
import Room from '../queue/Room'
import OperatorDashboard from '../queue/OperatorDashboard'
import SlotEditor from '../queue/slot/SlotEditor'
import EditTicket from '../ticket/EditTicket'

import SessionContext from './SessionContext';
import Pricing from '../pages/Pricing';
import SignIn from '../user/SignIn';
import SignUp from '../user/SignUp';
import TermsOfUse from '../user/TermsOfUse';
import ResetPassword from '../user/ResetPassword';
import ConfirmPasswordReset from '../user/ConfirmPasswordReset';

import Startpage from '../pages/Startpage';

import Rooms from '../user/Rooms';
import QueueSettings from '../queue/QueueSettings'





import axios from 'axios';
import PrivateRoute from './PrivateRoute';
import SignOut from '../user/SignOut';
import EditAccount from '../user/EditAccount';
import Accounts from '../user/Accounts';
import Categories from '../category/Categories';
import EditCategory from '../category/EditCategory';
import TicketCallBoard from '../ticket/TicketCallBoard';
import Domains from '../domain/Domains';
import EditDomain from '../domain/EditDomain';
import Places from '../places/Places';
import EditPlace from '../places/EditPlace';
import Wallet from '../ticket/Wallet';
import Favourites from '../queue/Favourites';
import Search from '../pages/Search';
import Slots from '../queue/slot/Slots';
import Dialog from '../queue/Dialog';
import EditResource from '../resources/EditResource';
import ResourceVariants from '../resourcevariants/ResourceVariants';
import EditResourceVariant from '../resourcevariants/EditResourceVariant';
import Groups from '../groups/Groups';
import EditGroup from '../groups/EditGroup';
import ResourcesPartition from '../resources/ResourcesPartition';
import Authorizations from '../authorization/Authorizations';
import EditAuthorization from '../authorization/EditAuthorization';
import ManageMultiQ from '../multiq/ManageMultiQ';
import BookingStep1 from '../booking/BookingStep1';
import BookingSelection from '../booking/BookingSelection';
import BookingVariants from '../booking/BookingVariants';
import BookingData from '../booking/BookingData';
import Slotmanager from '../pages/Slotmanager';
import ManageQ from '../queue/ManageQ';
import log from 'loglevel';
import CallBoard from '../callboard/CallBoard';

//const env = 'http://194.126.206.197';
//const bartenderUrl = 'http://localhost/bartender';
//const vqueueUrl = 'http://localhost/vqueue';

const prod = process.env.NODE_ENV === 'production';
log.setDefaultLevel(prod ? log.levels.DEBUG : log.levels.DEBUG);
log.trace("process.env.REACT_APP_ENVIRONMENT: "+process.env.REACT_APP_ENVIRONMENT);
log.trace("REACT_APP_VERSION " + `${process.env.REACT_APP_VERSION}`);
log.trace("REACT_APP_NAME " + `${process.env.REACT_APP_NAME}`);
log.trace("REACT_APP_BASE " + `${process.env.REACT_APP_BASE}`);
log.trace("REACT_APP_VQUEUE_URL " + `${process.env.REACT_APP_VQUEUE_URL}`);
log.trace("REACT_APP_BARTENDER_URL " + `${process.env.REACT_APP_BARTENDER_URL}`);
log.trace("REACT_APP_PLACES_URL " + `${process.env.REACT_APP_PLACES_URL}`);

log.debug("process.env.NODE_ENV " + `${process.env.NODE_ENV}`);
log.trace("loglevel trace message");
log.debug("loglevel debug message");
log.info("loglevel info message");
log.warn("loglevel warn message");
log.error("loglevel error message");
if(!process.env.REACT_APP_BASE) {
  if(process.env.NODE_ENV=="production") {
    log.trace("Setting PROD conf");
    config.set({ 
      baseUrl: 'https://app.viwaq.de',
      vqueueUrl: 'https://app.viwaq.de/vqueue',
      bartenderUrl: 'https://app.viwaq.de/bartender/',
      broadcaster: 'https://app.viwaq.de/broadcaster',
      placesUrl: 'https://app.viwaq.de/places'
        // bartenderUrl : 'https://192.168.1.95/bartender',
        // vqueueUrl : 'https://192.168.1.95/vqueue',
        // placesUrl : 'https://192.168.1.95/places'
    });
  } else { //development
    log.trace("Setting DEV conf");
    if(false) {
      config.set({ 
        baseUrl: 'http://localhost:3000',
        vqueueUrl: 'http://localhost:8097',
        bartenderUrl: 'http://localhost:8098',
        broadcaster: 'http://localhost:8100',
        placesUrl: 'http://localhost:8080'
      });
    }
    if(true) {
      config.set({ 
        baseUrl: 'https://app.viwaq.de',
        vqueueUrl: 'https://app.viwaq.de/vqueue',
        bartenderUrl: 'https://app.viwaq.de/bartender/',
        broadcaster: 'https://app.viwaq.de/broadcaster',
        placesUrl: 'https://app.viwaq.de/places'
      });
    }
      
  }
} else {
  log.trace("Using base from env: "+process.env.REACT_APP_BASE);
  config.set({ 
    baseUrl: process.env.REACT_APP_BASE,
    vqueueUrl: process.env.REACT_APP_BASE+'/vqueue',
    bartenderUrl: process.env.REACT_APP_BASE+'/bartender/',
    broadcaster: process.env.REACT_APP_BASE+'/broadcaster',
    placesUrl: process.env.REACT_APP_BASE+'/places'
  });
}


// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = SessionContext.getToken();
  log.trace("Auth Token: "+JSON.stringify(token));
  if(token && token.headers) {
    config.headers =  token.headers;
  }
  return config;
});







// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = ({ children }) => (
  <main>
           
    
           {children}
      <Switch>
        <Route exact path='/' component={Startpage}/>      
        
        <Route exact path='/signup' component={SignUp}/>  
        <Route exact path='/termsofuse' component={TermsOfUse}/>  
        <Route exact path='/login' component={SignIn}/>  
        <PrivateRoute exact path='/logout' component={SignOut}/>  
        <Route exact path='/resetPassword' component={ResetPassword}/>  
        <Route exact path='/confirmPasswordReset' component={ConfirmPasswordReset}/>  

        <Route exact path='/settings' component={Settings}/>  
        <PrivateRoute exact path='/myAccount' component={EditAccount}/>  
        <PrivateRoute exact path='/authorizations' component={Authorizations}/>  
        <PrivateRoute exact path='/editAuthorization' component={EditAuthorization}/>  
        <Route exact path='/globalSettings' component={GlobalSettings}/>    
        
        <PrivateRoute exact path='/accounts' component={Accounts}/>  
        <PrivateRoute exact path='/editAccount' component={EditAccount}/>  
        
        <Route exact path='/booking' render={(props) => <Domains {...props} bookingMode={true} /> }  />   
        <Route exact path='/booking/options' component={BookingStep1}/>  
        <Route exact path='/booking/selection' component={BookingSelection}/>  
        <Route exact path='/booking/data' component={BookingData}/>  
        <Route exact path='/booking/variants' component={BookingVariants}/>  
        
        
       
        <Route exact path='/chat' component={Dialog}/>
        
        
        <Route exact path='/wallet' component={Wallet} />   
        <Route exact path='/favourites' component={Favourites} />   
        <Route exact path='/room' component={Room}/>
        
        <Route exact path='/search' component={Search} />   
        
        
        
        
        <PrivateRoute exact path='/my' component={Slotmanager}/>  
        
        <Route exact path='/my/domains' component={Domains}/>  
        <Route exact path='/my/domains/edit' component={EditDomain}/>  
        <PrivateRoute exact path='/my/domains/categories' key="/my/domains/categories" component={Categories}/>  
        <Route exact path='/my/domains/categories/edit' component={EditCategory}/>  


        <Route exact path='/my/places' component={Places}/>  
        <Route exact path='/my/places/callboard' component={CallBoard}/>  
        <Route exact path='/my/places/edit' component={EditPlace}/>  

        <Route exact path='/my/groups' component={Groups}/>  
        <Route exact path='/my/groups/edit' component={EditGroup}/>  

        <Route exact path='/my/groups/resources' component={ResourcesPartition}/>  
        

        <Route exact path='/my/groups/resources/edit' component={EditResource}/>  
        
        <Route exact path='/my/groups/resources/variants' component={ResourceVariants}/>  
        <Route exact path='/my/groups/resources/variants/edit' component={EditResourceVariant}/>  

        <Route exact path='/my/groups/slotmanager' render={(props) => <ManageMultiQ {...props} key={12212}  />}  />  
        <PrivateRoute exact path='/my/groups/slotmanager/dashboard' component={OperatorDashboard}/>
        
        <PrivateRoute exact path='/my/groups/slotmanager/settings' component={QueueSettings}/>  
        
        <PrivateRoute exact path='/my/groups/slotmanager/settings/base' component={QueueEditor}/>  
        <PrivateRoute exact path='/my/groups/slotmanager/settings/categories' key="/my/groups/slotmanager/settings/categories" component={Categories}/>  
        
        <PrivateRoute exact path='/my/groups/slotmanager/settings/slots' component={Slots}/>  
        <PrivateRoute exact path='/my/groups/slotmanager/settings/slots/edit' component={SlotEditor}/>  
        
        
        <Route exact path='/callBoard' component={TicketCallBoard}/>        
        <Route exact path='/requestTicket' component={EditTicket}/>  
        <Route exact path='/editTicket' component={EditTicket}/>   
        <PrivateRoute exact path='/manageTicket' component={EditTicket}/>   
        <Route exact path='/reviewRequest' component={EditTicket}/>
        
         
        
        <Route exact path='/qrcode' component={QRCode}/>  
        <Route exact path='/waiterscanner' component={QRCodeQueueIn} title="Scan nen Code"/>  
        <PrivateRoute exact path='/operatorscanner' component={QRCodeQueueIn}/>  
        
        <Route exact path='/pricing' component={Pricing}/>  
        
        
        
        
      </Switch>
  </main>
)

export default Main