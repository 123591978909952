import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SessionContext from '../frame/SessionContext';
import { Container } from 'reactstrap';
import DeskHeader from '../pages/assets/DeskHeader';
import Crumbs from '../queue/Crumbs';
import { CssBaseline, Typography } from '@material-ui/core';
import config from 'react-global-configuration';
import { withTranslation } from 'react-i18next';

const convertMsToHMS = (milliseconds) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

const CallBoard = (props) => {
  const { t } = props;
  const [data, setData] = useState({});
  const [waitingParams, setWaitingParams] = useState(null);
  const { vPlace } = props.location;
  const { vDomain } = props.location;

  const [domain] = useState(vDomain?vDomain:SessionContext.getDomain());
  const [place, getPlace] = useState(vPlace?vPlace:SessionContext.getPlace());
  const [totalCases, setTotalCases] = useState(null);

 

  useEffect(() => {
    const totalCases = data.aggregatedCategories
          ? data.aggregatedCategories.reduce((total, category) => total + category.count, 0)
            : 0;

    setTotalCases(totalCases);
    
  }, [data]);

  useEffect(() => {
    let waitingParams={
      scope: 'LATEST',
      placeRef: vPlace.id
    };
    setWaitingParams(waitingParams);
  }, [vPlace]);

  useEffect(() => {
    if(waitingParams) {
      const fetchData = async () => {
        try {
          let uri = '/api/waitingTimeInternal';
          const url = config.get('bartenderUrl')+uri;
          const response = await axios.get(url, {
            params: waitingParams
          });
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [waitingParams]);

  
  function getQuickActionItems() {
    let quickActionItems = [];
   
    return quickActionItems;
  }

  function getMoreMenuItems() {

    let gridContent = [
     
      
    ];
    return gridContent;
  }

  if (!data) {
    return <Typography variant="body1">Loading...</Typography>;
  }

  
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader 
        title={domain?<Crumbs domain={domain}/>:t("all_places")}  
        backTo={"/my/domains"} 
        quickActions={getQuickActionItems()}
        moreMenuItems={getMoreMenuItems()}/>
      <CssBaseline />
      <Typography variant="h4">Wartezeit: {convertMsToHMS(data.waitingTime)}</Typography>
      <Typography variant="subtitle1">Reine Wartezeit: {convertMsToHMS(data.waitingTimePure)}</Typography>
      <Typography variant="subtitle1">Durchschnittliche Bearbeitungszeit: {convertMsToHMS(data.avgProcessingTime)}</Typography>
      <Typography variant="h5">Gesamtzahl der aktuellen Fälle: {totalCases}</Typography>
    </Container>
  );
};

export default withTranslation()(CallBoard);
